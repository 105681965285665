<script>
import {
    mdiChevronDown,
    mdiChevronUp,
    mdiOpenInNew,
    mdiTune,
    mdiNotePlusOutline
} from '@mdi/js'
import { mapFields } from "vuex-map-fields";
import ProfileAvatar from '@/layouts/components/action-bar/profile/ProfileAvatar.vue';
import globals from '../../globals';
import activityService from '../../services/activity.service';

export default {
    components: { ProfileAvatar },
    data() {
        return {
            loading: false,
            filteredActivities: [],
            allNotesAndActivities: [],
            groupedActivities: {},
            activityTypes: '',
            statecodes: '',
            statuscodes: '',
            searchTerm: '',
            filterType: 'All',
            filterState: 'All',
            filterStatus: 'All',
            selectedIndex: [],
            activityTypes: [],
            statecodes: [],
            statuscodes: [],
            isUpdating: 0,
            transparent: 'rgba(255, 255, 255, 0)',
            showFilters: false,
            showCreateNote: false,
            loadingNewNote: false,
            createNoteSubject: '',
            createNoteText: ''
        }
    },
    setup() {
        return {
            icons: {
                mdiChevronDown,
                mdiChevronUp,
                mdiOpenInNew,
                mdiTune,
                mdiNotePlusOutline
            },
        }
    },

    async mounted() {
        try {
            await this.getAllActivitiesSortedAndGrouped();

            this.activityTypes = this.$store.state.ActivityTypes;
            this.statecodes = this.$store.state.Statuscodes;
            this.statuscodes = this.$store.state.Statecodes;
        } catch (error) {
            this.error = true;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        async getAllActivitiesSortedAndGrouped() {
            this.loading = true;
            this.$store.commit('clearNotesAndActivities', []);
            // Need to clear the store

            await activityService.fetchAssociatedActivities(this.$store);
            await activityService.fetchAssociatedNotes(this.$store);
            let appName = localStorage.getItem("appName")
            if (globals.uDevApps.find(app => app === appName)) {
                await activityService.fetchTouchpoints(this.$store);
            }
            this.allNotesAndActivities = this.$store.state.AssciatedNotesAndActivities;

            this.groupActivities(this.allNotesAndActivities);

            this.loading = false;
        },
        async createNewNote() {
            this.loadingNewNote = true;
            const apiResponse = await activityService.createNote(this.createNoteSubject, this.createNoteText);
            console.log(apiResponse);
            this.createNoteSubject = '';
            this.createNoteText = '';
            this.loadingNewNote = false;
            this.getAllActivitiesSortedAndGrouped();
        },
        setExpand(index) {
            if (this.selectedIndex.includes(index)) {
                const arrIndex = this.selectedIndex.indexOf(index);
                delete this.selectedIndex[arrIndex];
                this.isUpdating++;
            } else {
                this.selectedIndex.push(index);
            }
        },
        async groupActivities(filtered) {
            this.groupedActivities = await activityService.groupActivities(filtered);
            this.groupedActivities.forEach((p) => {
                p.activities.forEach((c) => {
                    c.show = false
                })
            })
        },
        filterActivities() {
            var filtered = this.allNotesAndActivities;
            if (this.searchTerm != '') {
                filtered = filtered.filter((item => (item.subject.toUpperCase().includes(this.searchTerm.toUpperCase()) ||
                    (item.textNoTag.toUpperCase().includes(this.searchTerm.toUpperCase())))));
            }

            if (this.filterType != 'All') {
                filtered = filtered.filter((item) => item.type === this.filterType);
            }
            if (this.filterState != 'All') {
                filtered = filtered.filter((item) => item.statecode === this.filterState);
            }

            this.groupActivities(filtered);
        },
        // filterByStatus() {
        //     this.notesAndActivities = this.filterStatus === "All"
        //         ? (this.notesAndActivities = this.allNotesAndActivities)
        //         : this.allNotesAndActivities.filter((item) => item.statuscode === this.filterStatus);
        // },
        open(url) {
            window.open(url);
        }
    },
    // watch(selectedFilters, () => {
    // let filteredActivities = activityService.filterActivities(selectedFilters.value);
    // if (search.value) {
    //     groupedActivities.value = groupActivities(activityService.searchActivities(selectedFilters.value, search.value));
    // }
    // else {
    //     groupedActivities.value = groupActivities(filteredActivities);
    // }
    // startIndex.value = 0; endIndex.value = 9;
    // })
    computed: {
        ...mapFields([
            "ActivityTypes",
            "Statecodes",
            "Statuscodes",
            "AssciatedNotesAndActivities"
        ]),
    },
}
</script>

<template>
    <div>
        <h2>Notes & Activities</h2>
        <v-row style="padding-bottom:15px">
            <v-col cols="12" sm="6" md="9">
                <!-- <v-autocomplete label="Search"></v-autocomplete> -->
                <v-text-field placeholder="Search Subject or Description" v-model="searchTerm" rounded dense outlined
                    prepend-inner-icon="mdi-magnify" class="app-bar-search flex-grow-0" hide-details
                    @keyup="filterActivities"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="1" class="mr-3">
                <v-btn @click="showFilters = !showFilters" color="primary">
                    <v-icon small>{{ icons.mdiTune }}</v-icon>
                    Filters
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="1">
                <v-btn @click="showCreateNote = !showCreateNote" color="primary">
                    <v-icon small>{{ icons.mdiNotePlusOutline }}</v-icon>
                    New Note
                </v-btn>
            </v-col>
        </v-row>
        <div v-show="showFilters">
            <h2>Filter</h2>
            <v-row>
                <v-col>
                    <v-autocomplete @change="filterActivities" v-model="filterType" :items="activityTypes"
                        label="Activity Type"></v-autocomplete>
                </v-col>
                <v-col>
                    <v-autocomplete @change="filterActivities" v-model="filterState" :items="statecodes"
                        label="State"></v-autocomplete>
                </v-col>
                <!-- <v-col>
                    <v-autocomplete @change="filterByStatus" v-model="filterStatus" :items="statuscodes"
                        label="Status Reason"></v-autocomplete>
                </v-col> -->
            </v-row>
        </div>
        <div v-show="showCreateNote">
            <h2>Create New Note</h2>
            <br />
            <v-text-field dense outlined label="Subject" :maxlength="100" v-model="createNoteSubject"></v-text-field>
            <v-textarea dense outlined v-model="createNoteText" counter :maxlength="1000"
                label="Description"></v-textarea>
            <v-btn block dense color="primary" @click="createNewNote" :loading="loadingNewNote">Create Note</v-btn>
            <br /> <br />

        </div>
        <v-skeleton-loader v-show="loading" class="mx-auto" type="card-heading, image"></v-skeleton-loader>
        <v-sheet class="activityCard" elevation="5" v-for="group in groupedActivities" :key="group.id">
            <h3 style="padding-left:10px; padding-top:10px">{{ group.createMonthFormatted }}</h3>
            <br />
            <v-card v-for="activity in group.activities" :key="activity.id" style="margin-bottom: 13px">
                <v-hover v-slot="{ hover, props }">
                    <v-card :class="{ 'on-hover': hover }" v-bind="props">
                        <v-row>
                            <v-col style="max-width: 131px; margin-left: -15px">
                                <div id="chevron-btn" class="show-btn-chev" style="margin-top: -10px;">
                                    <v-btn large @click="setExpand(activity.unqIndex)" icon>
                                        <v-icon>
                                            {{ selectedIndex.includes(activity.unqIndex) ? icons.mdiChevronUp :
                                                icons.mdiChevronDown }}
                                        </v-icon>
                                    </v-btn>
                                </div>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, props }">
                                        <span small icon v-bind="props" v-on="on" style="padding-left:10px">
                                            <ProfileAvatar :avatar="activity"></ProfileAvatar>
                                        </span>
                                    </template>
                                    {{ activity.alt }}
                                </v-tooltip>

                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <span>
                                            <span style="font-weight: 900; " v-html="activity.subject"></span>&nbsp;
                                            <span style="font-style: italic;">{{ activity.type }}</span>
                                            <span> created on {{ activity.createDateFormatted }}</span> &nbsp;
                                            <span v-show="activity.type != 'Note'"
                                                :class="{ roundedStatusOpen: activity.statecode === 'Open' }"
                                                class="roundedStatus">{{
                                                    activity.statecode }}</span>
                                        </span>
                                        <div v-show="activity.type != 'Note' && activity.type != 'Touchpoint'"
                                            style="float:right; max-width:50px; margin: auto; padding-right:15px">
                                            <v-btn small id="open-btn" :class="{ 'show-btn': hover }"
                                                @click="open(activity.link)" :color="transparent">Open
                                                <v-icon> {{ icons.mdiOpenInNew }} </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-35px  !important; padding: 0 15px">
                            <v-col v-show="!selectedIndex.includes(activity.unqIndex)">
                                <p class="one-line mt-5" v-html="activity.textNoTag"> </p>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <div v-show="selectedIndex.includes(activity.unqIndex)" style="margin-top: 50px!important;"
                                :key="isUpdating">
                                <v-card-text>
                                    <div v-html="activity.text" style="overflow: hidden;"></div>
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-hover>
            </v-card>
        </v-sheet>
    </div>
</template>

<style scoped>
.v-card.on-hover.theme--dark {
    background-color: rgb(70, 69, 69);
}

.v-card.on-hover {
    background-color: #9fcbed;
}

.show-btn-chev {
    color: rgb(0, 0, 0) !important;
    display: block !important;
}

.show-btn {
    color: rgb(0, 0, 0) !important;
    display: block !important;
    background-color: rgba(255, 255, 255) !important;
}

.show-btn.on-hover {
    color: rgb(0, 0, 0) !important;
    display: block !important;
    background-color: rgb(175, 174, 174) !important;
}

#open-btn {
    position: inherit;
    float: right;
    margin-top: -10px;
    display: none;
}

#chevron-btn {
    position: inherit;
    float: left;
    /* margin-top: -10px; */
    display: none;
    max-width: 50px;
    margin: auto;
    padding-left: 15px;
    padding-top: 10px;
    margin-left: 7px;
}

/* .v-card {
    transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
    opacity: 0.6;
} */

.one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: normal;
}

.roundedStatus {
    border-radius: 25px;
    background-color: rgba(94, 86, 105, 0.08);
    padding: 6px;
}

.roundedStatusOpen {
    background-color: rgb(42, 199, 2, .3);
}

.closedActivity {
    color: rgb(42, 199, 2);
}

.activityCard {
    margin-bottom: 20px
}

.commentIcon {
    max-width: 30px !important;
    /* padding-left: 25px !important; */
}

.noteTitle {
    font-size: 1rem !important;
    cursor: pointer;
}

.commentTitle {
    font-size: 1rem !important;
}

.commentSubject {
    font-size: 1rem !important;
    color: darkgrey;
}

.noteSubject {
    font-size: 1rem !important;
}

.showMore {
    color: lightgray;
}

.noteDate {
    text-align: right;
    color: darkgrey;
}

.comment {
    padding-right: 15px;
    padding-left: 15px;
}

.newComment {
    padding-left: 25px;
    padding-bottom: 25px;

}

.statecode {
    margin-top: -4px;
}
</style>